<template lang="pug">
  div(style="background-color: white; min-height: 1024px;")
    section.redesign.header
      .header-overlay
        MenuHeader
    section.redesign.article
      .container
        .block-center
          .text-container(style="display: flex;")
            .text-column
              .text-block(style="width: 550px")
                p.oy-title ОЙ!
                p.label1 Похоже, мы не можем найти нужную вам страницу.
                p.label2 Код ошибки: 404
                //p.label3 Вот несколько полезных ссылок:
                //router-link.link(:to="{ name: 'Root' }") Главная

            .text-column
              .text-block(style="width: 400px")
                img(:src="require('images/404_man.jpg')" alt='')
</template>

<script>
import MenuHeader from 'components/Header'
import navigationMixin from 'mixins/navigation'

export default {
  name: 'Landing',
  components: {
    MenuHeader
  },
  mixins: [navigationMixin]
}
</script>

<style lang="sass">
.oy-title
  font-size: 120px !important
  color: #3C4870 !important
  font-weight: 700 !important
  margin-top: 134px !important
  margin-bottom: 74px !important
.label1
  font-size: 32px !important
  font-weight: 700 !important
  color: #3C4870 !important
  max-width: 495px !important
  line-height: 40px !important
.label2
  font-size: 18px !important
  font-weight: 600 !important
  color: #505571 !important
</style>
